import React from 'react';
import Navbar from './components/Navbar';
import AdventCalendar from './components/AdventCalendar';
import SnowEffect from './components/SnowEffect';
import backgroundSvg from './assets/images/background.svg';
import './styles/calendar.css';
const App = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <SnowEffect />
      <Navbar />
      <main className="py-12 bg-no-repeat bg-cover bg-fixed min-h-screen w-full" style={{
        backgroundImage: `url(${backgroundSvg})`
      }}>
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold text-center mb-8">
            Adventskalender 2024
          </h1>
          <p className="text-center text-gray-600 mb-12">
            Entdecken Sie jeden Tag einen neuen Kunden und seine Geschichte
          </p>
          <AdventCalendar />
        </div>
      </main>
    </div>
  );
};

export default App;