// src/data/customers.js
const customers = {
    "1": {
        "customerName": "",
        "customerLogo": "1_codeblick-logo.png",
        url: "https://codeblick.de/",
        "teamMember": {
            "name": "Franziska Leuchtle & Anna Haider",
            "position": "HR Assistent & Office Managerin",
            "image": "Franzi&Anna.png",
            "christmasTip": "Eine Tasse Tee, mit ein paar meiner Lieblingsplätzchen (Zitronenplätzchen gefüllt mit Lemon Curd 😊) und stimmungsvoller Weihnachtsmusik -  Perfekt, um die Hektik des Alltags hinter sich zu lassen und die Adventszeit in vollen Zügen zu genießen! 🫖✨"
        },
        "testimonial": "Herzlich Willkommen zu unserem ersten digitalen Adventskalender. 🎄<br>Hinter jedem Türchen erwartet euch ein einzigartiges Teammitglied - von kreativen Köpfen, leidenschaftlichen Designern über geniale Entwickler bis hin zu den Projektmanagern, die alle Fäden zusammenhalten und eure Visionen voranbringen. Klickt euch durch die Türchen und lernt die Menschen kennen, die eure Ideen Wirklichkeit werden lassen.<br>"
    },
    "2": {
        "customerName": "ENVIRIA",
        url: "https://www.enviria.de/",
        "customerLogo": "2_Enviria-logo.png",
        "teamMember": {
            "name": "Johannes Berthel",
            "position": "Head of Development",
            "image": "Johannes.png",
            "christmasTip": "Weihnachtsfilme: \"Mickys Weihnachtserzählung\", \"Rudolph mit der roten Nase\" und \"Stirb langsam\""
        },
        "testimonial": "ENVIRIA treibt mit über 500 Projekten die Energiewende voran und macht den Wechsel zu Solarstrom für Unternehmen kinderleicht. In einem agilen Projekt haben wir gemeinsam ihre moderne Webseite und ein verknüpftes Kundenportal realisiert – eine Zusammenarbeit, die Spaß gemacht hat!"
    },
    "3": {
        "customerName": "Umzugspreisvergleich",
        "customerLogo": "3_Umzugspreisvergleich-logo.png",
        url: "https://www.umzugspreisvergleich.de/",
        "teamMember": {
            "name": "Marta Del Re",
            "position": "Head of UX",
            "image": "Marta.png",
            "christmasTip": "Ganz klassisch, der Weihnachtsfilm “Tatsächlich Liebe” und das Weihnachtslied “Last Christmas”."
        },
        "testimonial": "Umzugspreisvergleich hilft Menschen, das richtige Umzugsunternehmen zu finden. Wir haben in diesem Jahr gemeinsam mit Umzugspreisvergleich deren Portale aus UX-Sicht optimiert. Das Ganze konnten wir mit Atomic Design aufbauen und in UX Sprints nach und nach umsetzen. Der Austausch und die Zusammenarbeit waren äußerst bereichernd und haben mir und meinem UX-Team sehr großen Spaß gemacht. Ich bin sehr dankbar für dieses Projekt und die tolle Partnerschaft. Wir freuen uns schon sehr auf die weitere Zusammenarbeit im Jahr 2025. Danke an Simon, Christian und die tollen Entwickler, die unsere Designs umgesetzt haben. "
    },
    "4": {
        "customerName": "GO IN",
        "customerLogo": "4_GOIN-logo.png",
        "url": "https://www.goin.de/",
        "teamMember": {
            "name": "Sebastian Lochbronner",
            "position": "Head of Account-Management",
            "image": "Sebi.png",
            "christmasTip": "Bester Weihnachtsfilm: \"Schöne Bescherung\""
        },
        "testimonial": "Ein spannendes Jahr an der Möbel-Front neigt sich dem Ende zu. Gemeinsam haben wir zahlreiche Optimierungen im Shop vorgenommen, wie beispielsweise ein Konfigurator-Facelift, eine HubSpot Integration sowie eigene Länder-Shops für Belgien, Niederlande und Luxembourg. Mich hat besonders die positive Entwicklung des digitalen Angebotsprozesses gefreut, der inzwischen zu einem wichtigen Vertriebskanal geworden ist. Für nächstes Jahr haben wir wieder zahlreiche erfolgversprechende Themen auf der Agenda, auf die ich mich schon jetzt sehr freue!<br>Danke an das gesamte Team, v.a. Tina, Jan, Thomas, Christin, Colin, Julia, Alina, … und an Nicolai für das langjährige Vertrauen in uns!"
    },
    "5": {
        "customerName": "Hygiene VOS",
        "customerLogo": "5_VOS-logo.png",
        "url": "http://www.hygiene-vos.de/",
        "teamMember": {
            "name": "Jonathan Irschl",
            "position": "Webentwickler",
            "image": "Jonny.png",
            "christmasTip": "Herr der Ringe im Free TV"
        },
        "testimonial": "Hygiene VOS bietet geschäftlichen sowie privaten Kunden alles, was es rund um das Thema Putzen und Sauberkeit zu wünschen gibt. Das Sortiment umfasst von Reinigungsmitteln über Haushaltspapier bis hin zu Körperpflege zahlreiche Artikel.<br>Wir durften als Agentur das Team dabei unterstützen, zahlreiche externe Marktplätze wie Amazon, Kaufland oder eBay in den Shop zu integrieren. Ein besonderes Augenmerk lag im Projekt auf Lösungen für B2B-Kunden, um diesen den Einkaufsprozess so angenehm wie möglich zu gestalten."
    },
    "6": {
        "customerName": "Rösterei Bohnenschmiede",
        "customerLogo": "6_Roesterei-Bohnenschmiede-logo.png",
        "url": "https://www.roesterei-bohnenschmiede.de/",
        "teamMember": {
            "name": "Andrea Lochbronner",
            "position": "Content Managerin",
            "image": "Andrea-2.png",
            "christmasTip": "Lifehack beim Plätzchenbacken 👍<br>Ärgert ihr euch beim Plätzchen-Ausstechen auch immer über Backpapier, das sich ständig wieder aufrollt und nicht da liegen bleibt, wo es soll?<br>Ein einfacher Tipp dazu: Knüllt den zugeschnittenen Backpapierstreifen einfach komplett zusammen. Wenn ihr ihn dann wieder auseinanderfaltet und glatt streicht, bleibt er da liegen, wo er soll."
        },
        "testimonial": "Die Rösterei Bohnenschmiede bietet in ihrem Onlineshop nicht nur hochwertigen Kaffee an, sondern hat sich auch einen Namen als Experte rund um das Thema Kaffee gemacht. Wir unterstützen das Bohnenschmiede-Team regelmäßig im Bereich Content-Erstellung & Ideenfindung. Probiert doch einfach mal das <a href='https://www.roesterei-bohnenschmiede.de/weihnachtskaffee-kaffee-plaetzchen-rezept/' style='font-weight:bold ' target='_blank' class='hover:text-blue-600 transition-colors'>Rezept mit den Kaffee-Plätzchen</a> aus - ist (nicht nur für Kaffee-Liebhaber) garantiert sehr lecker!"
    },
    "7": {
        "customerName": "Salmander",
        "customerLogo": "7_Salamander-logo.png",
        "url": "https://salamander-windows.com/",
        "teamMember": {
            "name": "Sebastian Bours",
            "position": "Webentwickler",
            "image": "Sebastian B..png",
            "christmasTip": "Weihnachten muss nicht perfekt sein, sondern vor allem gemütlich und mit Liebsten verbracht werden."
        },
        "testimonial": "Herzlichen Dank für die großartige Zusammenarbeit am Website-Projekt! Was ich besonders schätze, ist die Kommunikation auf Augenhöhe und die gegenseitige Wertschätzung der Arbeit auf beiden Seiten. Das offene Verständnis für das, was machbar ist, und die Bereitschaft, Herausforderungen aktiv anzugehen, machen das Arbeiten mit euch wirklich angenehm und produktiv. Ein Highlight war definitiv die Entwicklung des Konfigurators zur Lead-Generierung, und mit dem laufenden technischen Refactoring sind wir auf dem richtigen Weg, die Plattform noch zukunftsfähiger zu machen.<br>Ich freue mich schon darauf, im nächsten Jahr gemeinsam weiter durchzustarten. Danke für die tolle Zusammenarbeit und das Vertrauen!"
    },
    "8": {
        "customerName": "Soletek",
        "customerLogo": "8_Soletek-logo.png",
        "url": "https://www.soletek.de/",
        "teamMember": {
            "name": "Mathias Manz",
            "position": "Webentwickler",
            "image": "Matze.png",
            "christmasTip": "Stollenkonfekt is the way to go (am besten vom Aldi 😂)"
        },
        "testimonial": "Im Zuge der Shop-Erweiterung haben wir der Soletek GmbH kompetent zur Seite gestanden, einem mittelständischen Familienunternehmen mit langjähriger Fachkompetenz in den Bereichen Elektromobilität, LED-Technik, dekorativem Zubehör und Energie.<br>Mit unserer Expertise konnten wir das bestehende E-Commerce-System gezielt ausbauen und neue Funktionen integrieren. Das Ergebnis ist ein verbesserter Shop, der optimal auf die gewachsenen Anforderungen unseres Kunden zugeschnitten ist."
    },
    "9": {
        "customerName": "TNG - Technology Consulting",
        "customerLogo": "9_TNG-logo.png",
        "url": "https://www.tngtech.com/",
        "teamMember": {
            "name": "Sebastian Lochbronner",
            "position": "Head of Account-Management",
            "image": "Sebi.png",
            "christmasTip": "Bester Weihnachtsfilm: \"Schöne Bescherung\""
        },
        "testimonial": "Nach der initialen Konzeption und Entwicklung dürfen wir weiterhin an der Weiterentwicklung der BigTechDay Contentful-Website mitwirken. Der BigTechDay ist eine renommierte, jährlich stattfindende Technologie- und Innovationskonferenz, die führende Köpfe aus Wirtschaft, Wissenschaft und Technologie zusammenbringt. Mit einer Vielzahl von Vorträgen, Diskussionsrunden und Networking-Möglichkeiten bietet der BigTechDay nicht nur wertvolle Informationen, sondern auch die Möglichkeit, Kontakte zu knüpfen und sich mit Gleichgesinnten auszutauschen. Die Veranstaltung zieht Teilnehmer aus verschiedensten Branchen an, von Start-ups bis hin zu etablierten Unternehmen und ist ein Muss für alle, die die Zukunft der Technologie mitgestalten möchten. <br>Danke an das Team, insbesondere an Johanna und Yannick, für die mehrjährige Zusammenarbeit!"
    },
    "10": {
        "customerName": "HF Group",
        "customerLogo": "10_HFG-logo.png",
        "url": "https://www.hf-group.com/",
        "teamMember": {
            "name": "Alexander Schanbacher",
            "position": "Webentwickler",
            "image": "Alex.png",
            "christmasTip": "Ich kann “Nightmare before Christmas” empfehlen!"
        },
        "testimonial": "Die HF Mixing Group ist bekannt für ihre hohe Expertise in der Entwicklungsforschung und Herstellung von Spezialmaschinen für Gummi-, Reifen- und Speiseölhersteller und behauptet sich als renommierter Global Player in der Reifenindustrie. Wir haben die alte, monolithische Typo3 Website durch ein modernes Headless CMS abgelöst und sie damit für  eine effiziente & robuste Internationalisierung optimiert. Nachdem wir das Projekt mithilfe von Atomic Design neu gestaltet und in Contentful aufgebaut haben, konnten wir in diesem Jahr auch bei der Zusammenführung der unterschiedlichen Marken der HF Group unterstützen. Neben Design und Entwicklung haben wir auch im Bereich der Contenterstellung und -pflege gearbeitet. Es macht mir viel Spaß, an diesem Projekt mitzuwirken und einen Teil zum Erfolg beizutragen. Ich wünsche eine besinnliche Adventszeit und viel Glück für das kommende Jahr!"
    },
    "11": {
        "customerName": "Wuffwelt",
        "customerLogo": "11_WUFF-logo.png",
        "url": "https://www.wuffwelt.de/",
        "teamMember": {
            "name": "Andrea Lochbronner",
            "position": "Content Managerin",
            "image": "Andrea-2.png",
            "christmasTip": "Schneller Weihnachtspunsch: Wenn überraschend Gäste kommen, lässt sich auch ein fertig gekaufter Früchte-/Winterpunsch ganz schnell und einfach aufpeppen. Filetierte Orangen oder Äpfel und ½ Bio-Zitrone klein schneiden und mit einer Zimtstange, 4 Gewürznelken und 2 Sternanis in 1 Liter Punsch erwärmen und etwas ziehen lassen. (Zimtstange, Nelken, Sternanis vor dem Servieren entfernen.)  Rezept geht schnell und schmeckt (egal ob mit oder ohne Alkohol)."
        },
        "testimonial": "Wuffwelt hat sich mit hochwertigem Tierbedarf und als Experte für gesunde Ernährung für Hunde und Katzen einen begeisterten Kundenstamm aufgebaut. Das codeblick-Team hat den Onlineshop erfolgreich auf Shopware 6 umgezogen und mit einem frischen und ansprechenden Layout und Design versehen. Seither dürfen wir Wuffwelt auch regelmäßig mit SEO-optimierten Content-Beiträgen unterstützen."
    },
    "12": {
        "customerName": "Hellmut Ruck",
        "customerLogo": "12_HellmutRuck-logo.png",
        "url": "https://www.hellmut-ruck.de/",
        "teamMember": {
            "name": "Rodolfo Kunzmann",
            "position": "Account-Executive",
            "image": "Rodolfo.png",
            "christmasTip": "Die Plätzchen von meiner Mum!"
        },
        "testimonial": "Wir haben die HELLMUT RUCK GmbH, ein führender Fachgroßhandel für Podologie, Fußpflege, Kosmetik und Wellness, im Bereich der Barrierefreiheit umfassend unterstützt – sowohl durch ein Audit als auch durch gezielte Schulungen, um das Team optimal auf die kommenden gesetzlichen Änderungen vorzubereiten.<br>Mir gefällt besonders gut, dass Hellmut Ruck einen innovativen und modernen Ansatz verfolgt, der perfekt zu uns bei codeblick passt. Zudem schätze ich die persönliche Note des Projekts sehr."
    },
    "13": {
        "customerName": "Wolf Tabakwaren",
        "customerLogo": "13_WOF-logo.png",
        "url": "https://www.wolf-tabakwaren.de/",
        "teamMember": {
            "name": "Nicolas Meyer",
            "position": "Head of Development",
            "image": "Nico.png",
            "christmasTip": "Rinderfilet auf Sauce Bernaise und Kartoffelgratin "
        },
        "testimonial": "Wir haben für Wolf Tabakwaren erfolgreich den neuen B2B-Shop gelauncht, der ehemals auf WordPress basierte. Der Shop wurde direkt an ein Warenwirtschaftssystem (Wawi) angebunden, indem wir eine XML-Datenquelle genutzt haben. Später erfolgte die Umstellung auf eine API-gestützte Lösung, um eine vollautomatische Datensynchronisation zwischen Shop und Wawi zu gewährleisten."
    },
    "14": {
        "customerName": "BBB - Die Wasserprofis",
        "customerLogo": "14_BBB-logo.png",
        "url": "https://wasserprofis.de/",
        "teamMember": {
            "name": "Fabian Stern",
            "position": "UX Designer",
            "image": "Fabi.png",
            "christmasTip": "Eine leckere Zimtschnecke! (der Tipp gilt fürs ganze Jahr)"
        },
        "testimonial": "Die Wasserprofis aus Bobingen sind seit über 30 Jahren Hersteller professioneller Wasseraufbereitungssysteme. Im wöchentlichen Austausch unterstütze ich sie dabei, ihre Mission zu verwirklichen – nämlich dem Endverbraucher das bestmögliche Wasser bereitzustellen. Um es mit den Worten von Berthold Böhm (Geschäftsführer) zu sagen: „Wasser ist eben nicht nur nass.“ Ich freue mich auf das kommende Jahr und die spannenden Projekte, die bereits in den Startlöchern stehen!"
    },
    "15": {
        "customerName": "Langenberg",
        "customerLogo": "15_Langenberg-logo.png",
        "url": "https://langenberg.one/",
        "teamMember": {
            "name": "Serkan Savasal",
            "position": "Account-Manager",
            "image": "Serkan.png",
            "christmasTip": "Kevin allein zu Haus (Der Klassiker meiner Kindheit 😄)<br>"
        },
        "testimonial": "Die WIL Langenberg GmbH ist ein renommierter Anbieter für hochwertige Werbeartikel wie Taschen, Schirme und Lanyards. Gemeinsam mit dem großartigen Team haben wir daran gearbeitet, einen modernen Shopware 6 Onlineshop zu gestalten, der nicht nur die Vielfalt und Qualität ihrer Produkte perfekt widerspiegelt, sondern es auch ermöglicht, diese individuell zu konfigurieren. Besonders schätzen wir die kreative Zusammenarbeit, die Leidenschaft für Details und den offenen Austausch.<br>Ein besonderer Dank geht an Benni und sein Team – die Zusammenarbeit mit euch macht einfach Spaß! Wir freuen uns auf viele weitere spannende Projekte und eine langjährige Partnerschaft."
    },
    "16": {
        "customerName": "Röhm",
        "url": "https://www.roehm.biz/",
        "customerLogo": "16_Röhm-logo.png",
        "teamMember": {
            "name": "Florin Pandel",
            "position": "Webentwickler",
            "image": "Florin.png",
            "christmasTip": "Weihnachtsmusik: Alles von Michael Bublé! 🎄🎅 🎶"
        },
        "testimonial": "Röhm gehört zu den weltweit führenden Spannzeugherstellern – dank ihrer Innovationen und Qualität sind sie ein unverzichtbarer Partner in der Industrie. Wir sind stolz darauf, mit unserer Sales App ihren Außendienst zu unterstützen und Prozesse effizienter zu gestalten!"
    },
    "17": {
        "customerName": "Härtle Spiel + Freizeit",
        "customerLogo": "17_Härtle-logo.png",
        "url": "https://www.haertle.de/",
        "teamMember": {
            "name": "Serkan Savasal",
            "position": "Account-Manager",
            "image": "Serkan.png",
            "christmasTip": "Kevin allein zu Haus (Der Klassiker meiner Kindheit 😄)<br>"
        },
        "testimonial": "Mit Härtle Spiel + Freizeit verbindet uns eine langjährige, vertrauensvolle Zusammenarbeit, die immer wieder durch spannende Projekte bereichert wird. Die Begeisterung für Modellbau und Spielwaren spiegelt sich in jedem Detail ihres Angebots wider. Es ist eine Freude, das digitale Einkaufserlebnis gemeinsam kontinuierlich zu verbessern.<br>Unser besonderer Dank geht an Herrn Franz für das Vertrauen und die großartige Zusammenarbeit – wir freuen uns auf viele weitere gemeinsame Erfolge!"
    },
    "18": {
        "customerName": "Germar`s",
        "customerLogo": "18_Germar-logo.jpg",
        "url": "https://www.germars.de/",
        "teamMember": {
            "name": "Moritz Lamprecht",
            "position": "UX Designer",
            "image": "Moritz.png",
            "christmasTip": "Lieblings-Weihnachtslied: hello (and it's christmas) von KYTES"
        },
        "testimonial": "Ich freue mich, Germar's Best Burger in Schwabmünchen bei ihrem Design zu unterstützen. Hier trifft Qualität auf echte Leidenschaft – und das schmeckt man bei jedem Bissen. Mein Tipp: Probiert unbedingt den \"Chili Cheese Bomb Burger\" – die perfekte Kombination aus würziger Schärfe und cremigem Käse!"
    },
    "19": {
        "customerName": "Liquon",
        "customerLogo": "19_Liquon-logo.png",
        "url": "https://www.liquon.de/",
        "teamMember": {
            "name": "Alexander Bornhof",
            "position": "Webentwickler",
            "image": "Alexander B..png",
            "christmasTip": "Schlesische Lebkuchensauce mit geräucherten Würstchen, Kartoffeln und Sauerkraut - Nach Ommas Rezept"
        },
        "testimonial": "Im Rahmen unserer Betreuung des Onlineshops von Liquon, langjähriger Experte im Vertrieb hochwertiger und innovativer Schmierstoffe, Fluids und Chemikalien,  haben wir kontinuierlich Detailverbesserungen vorgenommen, um die Nutzerfreundlichkeit und Performance zu steigern. Zudem haben wir die Anforderungen der Produktsicherheitsverordnung erfolgreich integriert, um den rechtlichen Standards zu entsprechen. Wir freuen uns darauf, Liquon auch im nächsten Jahr mit unserer Expertise und Engagement zu begleiten."
    },
    "20": {
        "customerName": "Aquilin Röder",
        "customerLogo": "20_AQR-logo.png",
        "url": "https://www.orthobedarf-roeder.de/",
        "teamMember": {
            "name": "Jonathan Irschl",
            "position": "Webentwickler",
            "image": "Jonny.png",
            "christmasTip": "Herr der Ringe im Free TV<br>"
        },
        "testimonial": "Aquilin Röder ist seit vielen Jahren ein geschätzter Supportkunde mit einem Shopware 5 Shop. Im vergangenen Jahr haben wir Aquilin Röder bei der Umstellung auf Shopware 6 unterstützt. Ihr Onlineshop, orthobedarf-roeder.de, bietet ein breites Sortiment an orthopädischen Einlagen, verschiedenen chemischen Produkten und Gummimaterialien. Unser Ziel war es, diese Produkte optimal für B2B-Kunden zu präsentieren, damit niemand mehr mit dem falschen Fuß aufstehen muss."
    },
    "21": {
        "customerName": "Psychic",
        "customerLogo": "21_PSY-logo.png",
        "url": "https://www.psychic-shop.de/",
        "teamMember": {
            "name": "Mathias Manz",
            "position": "Webentwickler",
            "image": "Matze.png",
            "christmasTip": "Stollenkonfekt is the way to go (am besten vom Aldi 😂)"
        },
        "testimonial": "Das Herz von Psychic schlägt für Farben - und deshalb vertreibt das Team von Psychic eine breite Auswahl an Farben aller Art, von Sprühdosen über Wandfarben bis hin zu Produkten für Künstler oder Liebhaber der Urban-Art Szene. Daneben hat Psychic sich auf unterschiedliche Verfahren im Textildruck spezialisiert.<br>Wir haben Psychic dabei unterstützt, einen modernen Onlineshop nach ihren individuellen Vorstellungen zu entwickeln. Durch die enge Zusammenarbeit konnten wir einen E-Commerce-Auftritt schaffen, der perfekt zur Marke passt."
    },
    "22": {
        "customerName": "Ammann Treppen",
        "customerLogo": "22_AmmannTreppen-logo.png",
        "url": "https://www.ammann-treppen.de/",
        "teamMember": {
            "name": "Navid Behnami",
            "position": "Webentwickler",
            "image": "Navid.png",
            "christmasTip": "Triff Freunde auf dem Weihnachtsmarkt – Glühwein hilft, \"Deutsch\" zu sprechen und die Kälte zu vergessen!"
        },
        "testimonial": "Nachdem wir letztes Jahr den WooCommerce Shop für Ammann-Treppen gestaltet haben, durften wir diesen auch dieses Jahr weiterhin betreuen. Besonders begeistert mich die produktive Zusammenarbeit mit dem Team von Ammann Treppen und die gemeinsame Umsetzung von praktischen und innovativen Lösungen."
    },
    "23": {
        "customerName": "Singoldsand Festival",
        "customerLogo": "23_Singoldsand-logo.svg",
        "url": "https://www.singoldsand-festival.de/",
        "teamMember": {
            "name": "Jana Wolfschlag",
            "position": "UX Designerin",
            "image": "Jana.png",
            "christmasTip": "Digital Detox: Weihnachtszeit nutzen, um bewusst eine Pause von der digitalen Welt zu machen.  An den Weihnachtsfeiertagen einfach mal die Handybenachrichtigungen ausschalten und sich bewusst auf Familie und Freunde konzentrieren. ✨"
        },
        "testimonial": "Worauf ich mich nach der Winterzeit am meisten freue? Auf das Singoldsandfestival in Schwabmünchen! codeblick ist Co-Sponsor für das alljährlich dreitägige Jugend- und Popkulturfestival, das von einer Projektgruppe der Stadt Schwabmünchen und einem ehrenamtlichen Team von 250 Jugendlichen gestemmt wird. Wir, das UX-Designteam, begleiten das Singoldsand Festival während der Vorbereitungen und unterstützen es u.a. mit der Erstellung von Plakatentwürfen, Getränkeetiketten & Co. Schau doch mal unter www.singoldsand-festival.de vorbei!  "
    },
    "24": {
        "customerName": "",
        "customerLogo": "1_codeblick-logo.png",
        "url": "https://codeblick.de/",
        "teamMember": {
            "name": "Ibrahim Argun",
            "position": "Geschäftsführer",
            "image": "Ibo.png",
            "christmasTip": "Den Weihnachtsfilm “Liebe braucht keine Ferien” schaue ich mir jedes Jahr mit meiner Frau an!"
        },
        "testimonial": "🎄✨ Danke, dass Sie Teil unseres Weges sind!<br>Dieses Jahr war geprägt von Unsicherheiten – wirtschaftlich, politisch und gesellschaftlich. Zeiten wie diese zeigen uns, wie wichtig starke Partnerschaften sind. Ohne Sie, unsere Kunden und Partner, wären wir nichts. Gemeinsam haben wir Herausforderungen gemeistert, Erfolge gefeiert und einen Schritt in eine hoffnungsvollere Zukunft gemacht.<br>Lassen Sie uns auch im kommenden Jahr mit Mut, Zusammenhalt und Zuversicht weiter wachsen. Wir danken Ihnen von Herzen für Ihr Vertrauen und Ihre Unterstützung.<br>Frohe Feiertage und einen guten Start in ein neues Jahr voller Hoffnung und Möglichkeiten!<br>"
    }
};

export default customers;