import React from "react";

const Navbar = () => {
  return (
    <nav className="bg-navy-900 text-white p-4">
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <svg
            width="174"
            height="38"
            viewBox="0 0 174 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M150.82 11.4849C147.18 11.4849 144.114 14.5315 144.114 18.3487C144.114 22.2425 147.187 25.2818 150.82 25.2818H154.115C154.851 25.2818 155.448 25.8701 155.448 26.5958C155.448 27.3215 154.851 27.9097 154.115 27.9097H150.82C145.623 27.9097 141.447 23.6028 141.447 18.3487C141.447 13.1565 145.63 8.85693 150.82 8.85693H154.115C154.851 8.85693 155.448 9.44523 155.448 10.1709C155.448 10.8966 154.851 11.4849 154.115 11.4849L150.82 11.4849Z"
              fill="white"
            ></path>
            <path
              d="M171.329 9.24179C171.849 8.72865 172.694 8.72865 173.214 9.24179C173.735 9.75494 173.735 10.5869 173.214 11.1L165.823 18.3833L173.214 25.6666C173.735 26.1798 173.735 27.0117 173.214 27.5249C172.694 28.038 171.849 28.038 171.329 27.5249L162.995 19.3125C162.744 19.066 162.604 18.7318 162.604 18.3833C162.604 18.0348 162.744 17.7006 162.995 17.4542L171.329 9.24179Z"
              fill="white"
            ></path>
            <path
              d="M132.897 8.85693C133.633 8.85693 134.23 9.44523 134.23 10.1709V26.5958C134.23 27.3215 133.633 27.9097 132.897 27.9097C132.16 27.9097 131.563 27.3215 131.563 26.5958V10.1709C131.563 9.44523 132.16 8.85693 132.897 8.85693Z"
              fill="white"
            ></path>
            <path
              d="M112.415 8.85693C113.152 8.85693 113.749 9.44523 113.749 10.1709V25.2818H123.083C123.82 25.2818 124.417 25.8701 124.417 26.5958C124.417 27.3215 123.82 27.9097 123.083 27.9097H112.415C111.679 27.9097 111.082 27.3215 111.082 26.5958V10.1709C111.082 9.44523 111.679 8.85693 112.415 8.85693Z"
              fill="white"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M89.6411 9.24179C89.8911 8.99537 90.2303 8.85693 90.5839 8.85693H97.5476C100.531 8.85693 103.118 11.1316 103.118 14.0962C103.118 15.5697 102.492 16.871 101.499 17.8122C102.952 18.8152 103.918 20.437 103.918 22.3073C103.918 25.4678 101.216 27.9097 98.045 27.9097L90.5839 27.9097C89.8475 27.9097 89.2505 27.3215 89.2505 26.5958C89.2505 26.4823 89.2505 26.3938 89.2505 26.2882C89.2505 26.1827 89.2505 26.0522 89.2505 25.9388L89.2505 10.1709C89.2505 9.82243 89.391 9.48821 89.6411 9.24179ZM91.9174 25.2818L98.045 25.2818C99.8604 25.2818 101.252 23.9037 101.252 22.3073C101.252 20.7539 99.8679 19.3688 98.045 19.3688L91.9174 19.3688L91.9174 25.2818ZM91.9174 16.7409H97.5476C99.211 16.7409 100.451 15.4964 100.451 14.0962C100.451 12.7363 99.2185 11.4849 97.5476 11.4849H91.9174L91.9174 16.7409Z"
              fill="white"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.1047 11.483C6.52688 11.483 3.51409 14.5273 3.51409 18.3417C3.51409 22.2327 6.53396 25.2697 10.1047 25.2697H13.3429C14.0667 25.2697 14.6534 25.8576 14.6534 26.5827C14.6534 27.3079 14.0667 27.8958 13.3429 27.8958H10.1047C4.9972 27.8958 0.893066 23.592 0.893066 18.3417C0.893067 13.1533 5.00428 8.85693 10.1047 8.85693L13.3429 8.85693C14.0667 8.85693 14.6534 9.44479 14.6534 10.17C14.6534 10.8951 14.0667 11.483 13.3429 11.483L10.1047 11.483Z"
              fill="white"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M31.3127 11.4969C27.5129 11.4969 24.4325 14.5832 24.4325 18.3903C24.4325 22.1974 27.5129 25.2836 31.3127 25.2836C35.1125 25.2836 38.1929 22.1974 38.1929 18.3903C38.1929 14.5832 35.1125 11.4969 31.3127 11.4969ZM21.8115 18.3903C21.8115 13.1328 26.0654 8.87085 31.3127 8.87085C36.5601 8.87085 40.8139 13.1328 40.8139 18.3903C40.8139 23.6477 36.5601 27.9097 31.3127 27.9097C26.0654 27.9097 21.8115 23.6477 21.8115 18.3903Z"
              fill="white"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M69.0004 10.17C69.0004 9.44479 69.5872 8.85693 70.3109 8.85693H80.795C81.5188 8.85693 82.1055 9.44479 82.1055 10.17C82.1055 10.8951 81.5188 11.483 80.795 11.483H71.6214V17.0633H78.9717C79.6955 17.0633 80.2822 17.6512 80.2822 18.3763C80.2822 19.1015 79.6955 19.6894 78.9717 19.6894H71.6214V25.2697H80.795C81.5188 25.2697 82.1055 25.8576 82.1055 26.5827C82.1055 27.3079 81.5188 27.8958 80.795 27.8958H70.3109C69.5872 27.8958 69.0004 27.3079 69.0004 26.5827V10.17Z"
              fill="white"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M52.5179 8.85695L49.2798 8.85695C48.556 8.85695 47.9692 9.44481 47.9692 10.17V26.5827C47.9925 27.0204 48.2871 27.8958 49.2798 27.8958H52.5179C57.6255 27.8958 61.7296 23.592 61.7296 18.3417C61.7296 13.1533 57.6184 8.85695 52.5179 8.85695ZM52.5179 25.2697C56.0887 25.2697 59.1086 22.2327 59.1086 18.3417C59.1086 14.5273 56.0958 11.483 52.5179 11.483H50.5903V25.2697H52.5179Z"
              fill="white"
            ></path>
          </svg>
        </div>

        <a
          href="https://www.codeblick.de/projektanfrage/"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700"
        >
          Projektanfrage
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
