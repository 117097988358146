import React from "react";
import { X, ChevronLeft, ChevronRight } from "lucide-react";

const getImagePath = (filename, type) => {
  if (!filename) return "";
  try {
    if (type === "logo") {
      return require(`../data/Logos/${filename}`);
    } else if (type === "team") {
      return require(`../data/Mitarbeiterbilder/${filename}`);
    }
  } catch (error) {
    console.error(`Could not load image: ${filename}`, error);
    return "";
  }
  return filename;
};

const NavigationButton = ({ direction, onClick }) => (
  <button
    onClick={onClick}
    className={`fixed top-1/2 -translate-y-1/2 z-50 ${
      direction === "left" ? "left-2 md:-left-12" : "right-2 md:-right-12"
    } bg-white/80 hover:bg-white p-2 rounded-full shadow-lg transition-all`}
  >
    {direction === "left" ? (
      <ChevronLeft className="w-6 h-6" />
    ) : (
      <ChevronRight className="w-6 h-6" />
    )}
  </button>
);

const CustomerModal = ({
  isOpen,
  onClose,
  customerData,
  onNavigate,
  currentDoorNumber,
}) => {
  if (!isOpen || !customerData) return null;

  const logoPath = getImagePath(customerData.customerLogo, "logo");
  const teamMemberImagePath = getImagePath(
    customerData.teamMember.image,
    "team"
  );

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-start md:items-center justify-center overflow-y-auto">
      {currentDoorNumber !== 1 && (
        <NavigationButton direction="left" onClick={() => onNavigate("prev")} />
      )}
      {currentDoorNumber !== 24 && (
        <NavigationButton
          direction="right"
          onClick={() => onNavigate("next")}
        />
      )}{" "}
      <div className="bg-white rounded-lg p-4 md:p-8 max-w-4xl w-full m-4 relative animate-fadeIn my-8">
        {/* Navigation Arrows */}

        {/* Santa Hat */}
        <div className="absolute -top-12 -left-8 w-32 h-32 transform -rotate-12">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 80">
            <path
              d="M 10 60 Q 15 20 50 30 Q 80 38 90 15 Q 95 40 85 50 Q 75 60 50 60 Z"
              fill="#ff0000"
            />
            <path
              d="M 10 60 Q 30 58 50 60 Q 70 62 85 50 Q 87 53 85 56 Q 75 66 50 65 Q 30 64 10 60"
              fill="white"
            />
            <circle cx="90" cy="15" r="8" fill="white" />
            <path
              d="M 20 55 Q 40 45 60 50"
              stroke="#eb4444"
              fill="none"
              strokeWidth="2"
            />
          </svg>
        </div>

        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>

        {/* Customer Logo */}
        <div className="mb-10 mt-8 md:mt-2 text-left">
          <div className="w-48 flex items-start">
            <img
              src={logoPath}
              alt={`${customerData.customerName} Logo`}
              style={{ maxWidth: "" }}
              className="max-h-full object-contain"
            />
          </div>
        </div>

        {/* Main Content Area - Two Columns */}
        <div className="flex h-full flex-col md:flex-row gap-8">
          {/* Left Column - Testimonial */}
          <div className="md:w-2/4 flex flex-col justify-end border-r-0 md:border-r-2 border-b-2 md:border-b-0 border-blue-500 pb-6 md:pb-0 md:pr-6">
            <div>
              {customerData.customerName ? (
                <h2 className="pb-10 text-2xl font-bold text-gray-800 mb-4">
                  {customerData.url ? (
                    <a
                      href={customerData.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-blue-600 transition-colors"
                    >
                      {customerData.customerName}
                    </a>
                  ) : (
                    customerData.customerName
                  )}
                </h2>
              ) : null}
              <blockquote
                className="text-gray-600 italic"
                dangerouslySetInnerHTML={{
                  __html: `"${customerData.testimonial}"`,
                }}
              />
            </div>
          </div>

          {/* Right Column - Team Member */}
          <div className="md:w-2/4 flex flex-col justify-end">
            <div className="text-center">
              <div className="mb-4">
                <div className="w-32 h-32 md:w-40 md:h-40 rounded-full overflow-hidden mx-auto">
                  <img
                    src={teamMemberImagePath}
                    alt={customerData.teamMember.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="mt-4">
                  <p className="font-semibold text-lg">
                    {customerData.teamMember.name}
                  </p>
                  <p className="text-gray-600">
                    {customerData.teamMember.position}
                  </p>
                </div>
              </div>
              {/* Christmas Tip */}
              <div className="bg-gray-50 rounded-lg p-3 text-sm text-center text-gray-600">
                <p className="font-medium mb-1">Weihnachtstipp:</p>
                <p className="max-h-24 overflow-y-auto">
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        customerData.teamMember.christmasTip ||
                        "Frohe Weihnachten! 🎄",
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerModal;
