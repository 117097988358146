import React, { useEffect, useState } from "react";

const SnowEffect = () => {
  const [snowflakes, setSnowflakes] = useState([]);

  useEffect(() => {
    const createSnowflake = () => {
      const left = Math.random() * 100; // zufällige horizontale Position
      const animationDuration = 5 + Math.random() * 10; // zufällige Falldauer
      const size = Math.random() * 10 + 5; // zufällige Größe
      const delay = Math.random() * 5; // zufällige Startverzögerung

      return {
        id: Date.now() + Math.random(),
        style: {
          left: `${left}%`,
          animationDuration: `${animationDuration}s`,
          width: `${size}px`,
          height: `${size}px`,
          animationDelay: `${delay}s`,
        },
      };
    };

    // Initial snowflakes
    const initialSnowflakes = Array.from({ length: 50 }, createSnowflake);
    setSnowflakes(initialSnowflakes);

    // Add new snowflakes periodically
    const interval = setInterval(() => {
      setSnowflakes((prev) => {
        if (prev.length > 100) {
          return [...prev.slice(1), createSnowflake()];
        }
        return [...prev, createSnowflake()];
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed inset-0 pointer-events-none z-50">
      {snowflakes.map((snowflake) => (
        <div
          key={snowflake.id}
          className="absolute bg-white rounded-full opacity-70 animate-snow"
          style={snowflake.style}
        />
      ))}
    </div>
  );
};

export default SnowEffect;
